import React from 'react'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import ContactForm from '../components/contactForm'

import styles from '../components/contact-form.module.css'
// import { responsiveTitle1 } from '../components/typography.module.css'
import david from '../assets/david-headshot.jpg'


const ContactPage = () => {
  const title = "Thanks for contacting us!"

  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <section id="about-noovel" className={styles.contactSection}>
          <div className={styles.david}>
              <img src={david} className={styles.headshot} /><br />
              <div className={styles.contactInfo}>
                David Abehsera<br />
                Founder, Chief Consultant <br />
                <a className={styles.link} href="mailto:david@Noovel.co">david@noovel.co</a>
              </div>
          </div>
          <div className={styles.thankYou}>Thanks for contacting us. We'll follow up with you soon.</div>
        </section>
      </Container>
    </Layout>
  )
}

export default ContactPage
